import axios from 'axios';
import config from "@/config";

export async function loginUser(username, password) {
    return await axios.post(`${config.BACK_IP}/auth`, {username, password}, { headers: { token: localStorage.token } })
        .then(response => {
            return response
        })
        .catch(error => {
            throw error
        })
  }